$bg-color: rgb(29, 46, 46);
$color: #5ada5a;

#folio {
    padding: 25px;
    background-color: $bg-color;
    .folio-title {
        margin-bottom: 12px;
        font-family: 'Press Start 2P', cursive;
        color:  #f7fff7;
        text-align: center;
    } 
    .folio-slider-container {
        height: auto;
        padding: 8px 25px;
        margin: 2px;
    }
}

.folio-slider-item {
    text-align: center;
    color:  #f7fff7;
    p {
        font-family: 'Press Start 2P', cursive;
        font-size: 12px;
        font-weight: lighter;
    }
    img {
        margin: 12px auto;
        padding: 12px;
        width: 180px;
    }
    a {
        font-family: 'Press Start 2P', cursive;
        font-size: 12px;
        font-weight: lighter;
        &:hover {
            color: $color;
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 800px) { 
   
    .folio-slider-item {
        p {
            font-size: 10px;
            font-weight: lighter;
        }
        img {
            width: 120px;
        }
        a {
            font-size: 10px;
        }
    }

}