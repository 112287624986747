.footer {
    height: 80px;
    font-family: 'Press Start 2P', cursive;
    background-color: rgba(33, 26, 48, 0.993);
    color: whitesmoke;
    border-top: solid 1px #5ada5a28;
    .footer-year {
        margin-top: 12px;
    }
    .footer-quickmenu {
        ul {
            list-style-type: none;
            li {
                a {
                    font-size: 8px;
                    &:hover {
                        text-decoration: none;
                        color: #5ada5a;
                    }
                }
            }
            
        }
        
    }
}