#about {
    background-color: rgb(29, 46, 46);
    padding: 50px 0px;
}

.about-title-animation {
    text-align: center;
    margin: 12px 50px;
    width: 420px;
    margin: auto;
    h1 {
        padding: 12px;
        font-family: 'Press Start 2P', cursive;
        font-size: 2rem;
        color:  #f7fff7;
        overflow: hidden;
        border-right: .15em solid rgba(158, 10, 196, 0.767);
        white-space: nowrap;
        margin: 0 auto;
        letter-spacing: .15em;
        animation:
            typing 10s steps(40, end) infinite,
            blink-caret .75s step-end infinite;
    }
}

@keyframes typing {
    0% { width: 0 }
    8% {width: 100%;}
    100% { width: 100% }
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(177, 32, 221); }
}

.about-content {
    background-color: rgba(0, 0, 0, 0.438);
    padding: 10px;
    margin: 12px 120px;
    border: 1px solid rgba(255, 255, 255, 0.603);
    p {
        font-family: 'Press Start 2P', cursive;
        font-size: 14px;
        font-weight: lighter;
        color: rgba(153, 255, 106, 0.945);
        margin: 8px;
        u {
            margin: 2px 0px;
            color: whitesmoke;
        }
    }
    .prompt {
        color: rgba(245, 245, 245, 0.856);
    }
}

@media screen and (max-width: 800px) { 
    .about-title-animation {
        width: 300px;
        h1 {
            font-size: 1.5rem;
        }
    }

    @keyframes typing {
        0% { width: 0 }
        8% {width: 100%;}
        100% { width: 100% }
    }

    .about-content {
        background-color: rgba(0, 0, 0, 0.438);
        padding: 10px;
        margin: 12px 12px;
        border: 1px solid rgba(255, 255, 255, 0.603);
        p {
            font-family: 'Press Start 2P', cursive;
            font-size: 12px;
            color: rgba(153, 255, 106, 0.945);
        }
    }
}