$font-color: #5ada5a;

.navtop-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(33, 26, 48, 0.692);
  padding: 6px;
  font-family: "Press Start 2P", cursive;
  color: $font-color;
  z-index: 9999;
  .navtop-logo {
    text-align: center;
    width: auto;
    float: left;
    margin-top: 6px;
    h5 {
      display: inline;
      font-size: 1rem;
    }
  }
  .navtop-info {
    width: auto;
    text-align: center;
    color: #f3e5f8;
    float: left;
    margin-top: 3.5px;
    h5 {
      width: 100%;
      font-size: 0.5rem;
      display: inline;
      text-align: center;
      margin-left: 15px;
    }
  }
  .navtop-menu {
    float: right;
    text-align: center;
    font-size: 0.6rem;
    color: rgba(217, 214, 224, 0.884);
    .cube-menu {
      display: inline-block;
      width: auto;
      margin: 2px 0px;
      text-align: center;
      li {
        display: inline-block;
        margin: 2px;
        padding: 0px 2px;
        a {
          font-size: 0.5rem;
          text-decoration: none;
          &:hover {
            color: $font-color;
          }
        }
      }
    }
    .cube-social-icons {
      display: inline-block;
      width: auto;
      text-align: right;
      margin: 2px 0px;
      .cube-social-img {
        width: 25px;
        opacity: 0.9;
      }
      li {
        display: inline-block;
        margin: 2px;
        padding: 0px 2px;
        a {
          font-size: 0.5rem;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .navtop-container {
    .navtop-logo {
      float: none;
      width: 100%;
      text-align: center;
      h5 {
        font-size: 0.9rem;
      }
    }
    .navtop-info {
      display: none;
    }
    .navtop-menu {
      width: auto;
      text-align: center;
      float: none;
      li {
        a {
          font-size: 2px;
        }
      }
    }
  }
}
