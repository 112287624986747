.article {
    height: auto;
    background-image: linear-gradient( #201A23, rgb(29, 46, 46));
    .article-container {
        margin-top: 120px;
        padding-bottom: 50px;
        font-family: 'Press Start 2P', cursive;
        color: oldlace;
        text-align: center;
        ol {
            text-align: left;
            margin-left: 50px;
        }
        p {
            font-family: 'Share Tech Mono', monospace;
            padding: 12px;
            text-align: left;
            font-size: 1.7rem;
            font-weight: lighter;
        }
    }
}

@media screen and (max-width: 500px) { 

    .article {
        padding-top: 50px;
        .article-container {
            p {
                font-size: 1.5rem;
            }
            ol {
                font-size: 0.8rem;
            }
        }
    }
}

