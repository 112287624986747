.privacy-policy {
    height: 100vh;
    background-image: linear-gradient( #201A23, rgb(29, 46, 46));
    padding-top: 150px;
    .privacy-policy-container {
        font-family: 'Press Start 2P', cursive;
        text-align: center;
        color: oldlace;
        p {
            font-size: 0.8rem;
            font-weight: lighter;
        }
    }
}

@media screen and (max-width: 480px) { 
    .privacy-policy {
        height: 100%;
    }
}
