.header {
    background-image: linear-gradient( #201A23, rgb(29, 46, 46));
}

#root > div > div > div > div > div.header-menu.col-md-12 {
    position: absolute;
    top: 50px;
	left: 0px;
    text-align: center;
    color: #EAE6E5;
}

#root > div > div > div > div > div.text-center.col-md-12 {
    position: absolute;
    top: 530px;
	left: 0px;
}

#root > div > div > div > div {
    margin: 0px;
}

//Cube
$face-color: #201a23fd;
$translate-z: 157px;

.scene {
    display: inline-block;
    margin: 90px 0px;
    width: 300px;
    height: 300px;
    perspective: 500px;
    perspective-origin:center;
}

.cube {
    width: 300px;
    height: 300px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(10px);
    transition: ease-in-out transform 0.8s;
}
  
.cube.show-front  { transform: translateZ(10px) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(10px) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(10px) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(10px) rotateY(  90deg); }
.cube.show-top    { transform: translateZ(10px) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(10px) rotateX(  90deg); }

.cube__face {
    position: absolute;
    width: 300px;
    height: 300px;
    border: 2px solid rgba(255, 255, 255, 0.308);
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: 'Press Start 2P', cursive;
    color: #5ada5a;
    padding: 8px;
    h3 {
        font-size: 1rem;
        margin: 5px;
    }
    h5 {
        font-size: 0.8rem;
        margin: 5px;
    }
    p {
        font-weight: 400;
        font-size: 8px;
        color: white;
        text-align: center;
        margin: 8px;
        a {
            &:hover {
                text-decoration: none;
                color: #5ada5a;
            }
        }
    }
}

.cube-home-image {
    width: 180px;
    margin: 8px;
    padding: 0;
    border-radius: 50%;
}

.header-about-gif {
    width: 180px;
}
  
.cube__face--front { 
    background: $face-color;
    padding-top: 90px;
}
.cube__face--right  { background: $face-color; }
.cube__face--back   { background: $face-color; }
.cube__face--left   { background: $face-color; }
.cube__face--top    { background: $face-color; }
.cube__face--bottom { background: $face-color; }

.cube__face--front  { transform: rotateY(  0deg) translateZ($translate-z); }
.cube__face--right  { transform: rotateY( 90deg) translateZ($translate-z); }
.cube__face--back   { transform: rotateY(180deg) translateZ($translate-z); }
.cube__face--left   { transform: rotateY(-90deg) translateZ($translate-z); }
.cube__face--top    { transform: rotateX( 90deg) translateZ($translate-z); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ($translate-z); }

//Menu buttons
.header-menu-buttons {
    width: 100%;
    list-style-type: none;
    border-top: 3px solid #beef9e23;
    border-bottom: 3px solid #beef9e23;
    z-index: 9999;
  li {
    display: inline-block;
    padding: 6px 0;
    span {
      --fill-color: #5b9ad8;
      padding: 4px 0;
      font: 700 1.6rem Raleway, sans-serif;
      text-transform: uppercase;
      -webkit-text-stroke: 1px var(--fill-color);
      background: linear-gradient(var(--fill-color) 0 100%) left / 0 no-repeat;
      color: transparent;
      background-clip: text;
      transition: 0.5s linear;
      text-decoration: none;
      &:hover {
        background-size: 100%;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

//particles background
.particles {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 577px;
    z-index: 0;
}

@media screen and (max-width: 900px) {
    
    #root > div > div > div > div > div.header-menu.col-md-12 {
        top: 100px;
    }

    //Cube
    $translate-z: 117px;

    .scene {
        display: inline-block;
        margin: 90px 0px;
        width: 230px;
        height: 230px;
        perspective: 500px;
        perspective-origin:center;
    }

    .cube {
        width: 230px;
        height: 230px;
        position: relative;
        transform-style: preserve-3d;
        transform: translateZ(10px);
        transition: ease-in-out transform 0.8s;
    }

    .cube.show-front  { transform: translateZ(50px) rotateY(   0deg); }
    .cube.show-right  { transform: translateZ(50px) rotateY( -90deg); }
    .cube.show-back   { transform: translateZ(50px) rotateY(-180deg); }
    .cube.show-left   { transform: translateZ(50px) rotateY(  90deg); }
    .cube.show-top    { transform: translateZ(50px) rotateX( -90deg); }
    .cube.show-bottom { transform: translateZ(50px) rotateX(  90deg); }

    .cube__face {
        width: 230px;
        height: 230px;
        h3 {
            font-size: 0.7rem;
        }
        h5 {
            font-size: 0.5rem;
        }
        p {
            font-weight: 400;
            font-size: 6px;
            color: white;
        }
        a {
            font-size: 0.5rem;
        }
    }

    .cube-home-image {
        width: 110px;
        margin: 8px;
        padding: 0;
        border-radius: 50%;
    }

    .header-about-gif {
        width: 120px;
    }

    .cube__face--front  { transform: rotateY(  0deg) translateZ($translate-z); }
    .cube__face--right  { transform: rotateY( 90deg) translateZ($translate-z); }
    .cube__face--back   { transform: rotateY(180deg) translateZ($translate-z); }
    .cube__face--left   { transform: rotateY(-90deg) translateZ($translate-z); }
    .cube__face--top    { transform: rotateX( 90deg) translateZ($translate-z); }
    .cube__face--bottom { transform: rotateX(-90deg) translateZ($translate-z); }

    //buttons menu
    .header-menu-buttons > li > span {
        font: 700 1rem Raleway, sans-serif;
    }

    //particles background
    .particles {
        height: 532px;
    }

    #root > div > div > div > div > div.text-center.col-md-12 {
        top: 490px;
    }

}

@media screen and (max-width: 500px) { 

    //buttons menu
    .header-menu-buttons > li > span {
        font: 700 1rem Raleway, sans-serif;
    }

    //particles background
    .particles {
        height: 568px;
    }
}