.blog {
    height: 100vh;
    background-image: linear-gradient( #201A23, rgb(29, 46, 46));
    .blog-article-container {
        margin-top: 150px;
        font-family: 'Press Start 2P', cursive;
        text-align: center;
        a {
            &:hover {
                text-decoration: none;
                color: #5ada5a;
            }
        }
    }
}

