html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
}

html {
    scroll-behavior: smooth;   
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* loading animation */
.loading {
	font-family: 'Press Start 2P', cursive;
	font-size: 8px;
	color: cornsilk;
	text-align: center;
	margin: 200px auto 600px auto;
	height: 200px;
	width: 200px;
	background-color: rgba(137, 43, 226, 0.822);
}

.square {
	background-color: #5ada5a;
	height: 45%;
	width: 50%;
	animation: move-inside 1s ease-in-out forwards infinite alternate;
}

@keyframes move-inside {
	0% {
		transform: translateX(0);
	}
	33% {
		transform: translateY(100%)
	}
	66% {
		background-color: rgb(45, 45, 218);
		transform: translateX(100%) translateY(100%);
	}
	100% {
		background-color: rgb(240, 22, 229);
		transform: translateX(100%) ;
	}
}